<template>
  <b-dropdown-form class="py-1 dropdown-filter-form-n">
    <!-- <b-form-group
      label="Status"
      label-for="dropdown-form-status"
    >
      <v-select
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :value="statusFilter"
        :options="statusOptions"
        class="w-100"
        :clearable="false"
        :reduce="val => val.value"
        @input="(val) => $emit('update:statusFilter', val)"
      />
    </b-form-group> -->
    <b-row>
      <b-col cols="10">
        <p>Filters</p>
      </b-col>
      <b-col cols="2">
        <feather-icon
          icon="XIcon"
          size="16"
          class="align-right"
          @click="$emit('emitHideFilter')"
        />
      </b-col>
    </b-row>
    <b-form-group label="Show in Portal">
      <b-form-radio-group
        v-model="prospectBookableValue"
        :options="radioOptions"
        name="prospectBookable-radios-stacked"
        stacked
      />
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group label="Customers Only">
      <b-form-radio-group
        v-model="customerBookableValue"
        :options="radioOptions"
        name="customerBookable-radios-stacked"
        stacked
      />
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group label="Status">
      <b-form-checkbox
        v-for="option in statusOptions"
        :key="option.value"
        v-model="statusFilterValue"
        :value="option.value"
        name="flavour-3a"
      >
        {{ option.label }}
      </b-form-checkbox>
    </b-form-group>
    <b-button
      variant="outline-primary"
      size="sm"
      class="mr-1"
      @click="clearFilter"
    >
      Clear All
    </b-button>
    <b-button
      variant="primary"
      size="sm"
      @click="applyFilter"
    >
      Apply Filter
    </b-button>
  </b-dropdown-form>
</template>

<script>
import {
  BDropdownForm, BFormGroup, BButton, BFormRadioGroup, BFormCheckbox, BRow, BCol,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BDropdownForm,
    BFormGroup,
    BFormRadioGroup,
    BFormCheckbox,
    BRow,
    BCol,
    // vSelect,
  },
  props: {
    statusFilter: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    prospectBookable: {
      type: [String, null],
      default: null,
    },
    customerBookable: {
      type: [String, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      prospectBookableValue: this.prospectBookable,
      customerBookableValue: this.customerBookable,
      statusFilterValue: JSON.parse(JSON.stringify(this.statusFilter)) || [],
      radioOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
    }
  },
  watch: {
    prospectBookable: {
      immediate: true,
      handler(val) {
        this.prospectBookableValue = val
      },
    },
    customerBookable: {
      immediate: true,
      handler(val) {
        this.customerBookableValue = val
      },
    },
    statusFilter: {
      immediate: true,
      handler(val) {
        this.statusFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
  },
  methods: {
    applyFilter() {
      this.$emit('update:prospectBookable', this.prospectBookableValue)
      this.$emit('update:customerBookable', this.customerBookableValue)
      this.$emit('update:statusFilter', this.statusFilterValue)

      this.$emit('emitHideFilter')
    },
    clearFilter() {
      this.prospectBookableValue = null
      this.customerBookableValue = null
      this.statusFilterValue = []
      this.$emit('update:prospectBookable', this.prospectBookableValue)
      this.$emit('update:customerBookable', this.customerBookableValue)
      this.$emit('update:statusFilter', this.statusFilterValue)

      this.$emit('emitHideFilter')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
